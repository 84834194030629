<template>
  <b-navbar style="background-color: white; box-shadow: none" class="header-navbar navbar-shadow align-items-center navbar-brand-center navbar-fixed">
    <b-container class="text-light text-center">
      <b-col>
        <b-row class="">
          <b-col md="4" class="">
            <!-- <b-button variant="white" size="sm" :to="{ name: 'paket-detail', params: { id: paketDetail.slug } }"><feather-icon icon="ArrowLeftIcon" class="mr-50" />Kembali</b-button></b-col
          > -->
            <b-button variant="white" size="sm" @click="$router.go(-1)"><feather-icon icon="ArrowLeftIcon" class="mr-50" />Kembali</b-button></b-col
          >
          <b-col md="4" class=""><b-img :src="img" rounded width="64px" style="align: center" /></b-col>
        </b-row>
      </b-col>
    </b-container>
  </b-navbar>
</template>

<script>
import { BRow, BCol, BContainer, BNav, BNavItem, BFormRadio, BImg, BLink, BNavbarNav, BNavbar, BButton } from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BContainer,
    BNav,
    BNavItem,
    BFormRadio,
    BImg,
    BLink,
    BNavbar,
    BButton,
    BNavbarNav,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
    headerData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      img: require("@/assets/images/elp/ELP.png"),
      selected: "center",
    };
  },
  computed: {
    paketDetail() {
      return this.$store.state.paketTryout.detail;
    },
  },
};
</script>
