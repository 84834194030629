var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "user-profile"
    }
  }, [_c('b-navbar', {
    staticClass: "header-navbar navbar-shadow align-items-center navbar-brand-center navbar-fixed"
  }, [_c('navbar')], 1), _c('b-container', {
    staticClass: "mb-3"
  }, [_c('br'), _c('br'), _c('center', [_c('b-card', [_c('v-select', {
    attrs: {
      "id": "sekolah",
      "options": _vm.listJadwal,
      "label": "name",
      "placeholder": "Pilih Jadwal"
    },
    model: {
      value: _vm.jadwal,
      callback: function callback($$v) {
        _vm.jadwal = $$v;
      },
      expression: "jadwal"
    }
  })], 1), _c('br'), _vm.paketDetail.rasionalisasi_type == 'utbk' ? _c('div', {}, [_c('b-card', [_c('h3', [_vm._v(" " + _vm._s(_vm.hasilRasionalisasi.title) + " "), _c('b', [_vm._v(_vm._s(_vm.hasilRasionalisasi.name))])])]), _c('b-card', {
    staticStyle: {
      "margin-left": "30%",
      "margin-right": "30%"
    }
  }, [_c('b-card-body', {
    staticClass: "bg-primary"
  }, [_c('b-row', [_c('b-col', {
    staticClass: "text-white",
    attrs: {
      "md": "6"
    }
  }, [_vm._v(" Rank Saya "), _c('br'), _c('b', [_vm._v(" " + _vm._s(_vm.hasilRasionalisasi.ranking.ke))]), _vm._v("/" + _vm._s(_vm.hasilRasionalisasi.ranking.dari) + " ")]), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-button', {
    attrs: {
      "variant": "outline-light"
    },
    on: {
      "click": _vm.lihatRank
    }
  }, [_vm._v("Lihat Rank Lengkap")])], 1)], 1)], 1)], 1), _vm.hasilRasionalisasi.hasil_rasionalisasi[0] != null ? _c('div', {}, [_c('b-card', {
    staticStyle: {
      "margin-left": "7%",
      "margin-right": "7%"
    }
  }, [_c('b-row', _vm._l(_vm.hasilRasionalisasi.hasil_rasionalisasi, function (item, i) {
    return _c('b-col', {
      key: i,
      staticClass: "mt-3",
      attrs: {
        "md": "6"
      }
    }, [_c('table', {
      staticClass: "table table-bordered"
    }, [_c('tr', [_c('th', {
      staticClass: "text-center bg-primary text-white",
      attrs: {
        "colspan": "2"
      }
    }, [_vm._v(" Pilihan " + _vm._s(item.pilihan_ke) + " ")])]), _c('tr', [_c('td', [_vm._v("Nama")]), _c('td', [_vm._v(_vm._s(item.university_info.name))])]), _c('tr', [_c('td', [_vm._v("Target Jurusan")]), _c('td', [_vm._v(_vm._s(item.university_info.major_name))])]), _c('tr', [_c('td', [_vm._v("Nilai Ambang Batas")]), _c('td', [_vm._v(_vm._s(item.nilai_ambang_batas))])]), _c('tr', [_c('td', [_vm._v("Total Skor")]), _c('td', [_vm._v(_vm._s(item.score_total))])]), _c('tr', [_c('td', [_vm._v("Peluang Lulus")]), _c('td', [item.peluang_lulus == false ? _c('b-badge', {
      staticClass: "ml-1",
      attrs: {
        "variant": "light-danger"
      }
    }, [_vm._v("Tidak Lolos")]) : _c('b-badge', {
      staticClass: "ml-1",
      attrs: {
        "variant": "light-success"
      }
    }, [_vm._v("Lolos")])], 1)]), _c('tr', [_c('td', [_vm._v("Peluang Masuk")]), _c('td', [_c('b-badge', {
      staticClass: "ml-1",
      attrs: {
        "variant": "light-secondary"
      }
    }, [_vm._v(_vm._s(item.evaluasi.status_peluang_masuk))])], 1)]), _c('tr', [_c('td', [_vm._v("Pesan")]), _c('td', [_c('p', [_vm._v(_vm._s(item.evaluasi.message))])])])])]);
  }), 1)], 1)], 1) : _vm._e()], 1) : _vm._e(), _vm.paketDetail.rasionalisasi_type == 'mandiri' ? _c('div', {}, [_vm.hasilRasionalisasi.ranking ? _c('b-card', {
    staticStyle: {
      "margin-left": "30%",
      "margin-right": "30%"
    }
  }, [_c('b-card-body', {
    staticClass: "bg-primary"
  }, [_c('b-row', [_c('b-col', {
    staticClass: "text-white",
    attrs: {
      "md": "6"
    }
  }, [_vm._v(" Rank Saya "), _c('br'), _c('b', [_vm._v(" " + _vm._s(_vm.hasilRasionalisasi.ranking.ke))]), _vm._v("/" + _vm._s(_vm.hasilRasionalisasi.ranking.dari) + " ")]), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-button', {
    attrs: {
      "variant": "outline-light"
    },
    on: {
      "click": _vm.lihatRank
    }
  }, [_vm._v("Lihat Rank Lengkap")])], 1)], 1)], 1)], 1) : _vm._e(), _vm.hasilRasionalisasi != null ? _c('div', {}, [_c('b-card', {
    staticStyle: {
      "margin-left": "7%",
      "margin-right": "7%"
    }
  }, [_c('b-row', _vm._l(_vm.hasilRasionalisasi.selected_majors, function (item, i) {
    return _c('b-col', {
      key: i,
      attrs: {
        "md": "6"
      }
    }, [_c('table', {
      staticClass: "table table-bordered"
    }, [_c('tr', [_c('th', {
      staticClass: "text-center bg-primary text-white",
      attrs: {
        "colspan": "2"
      }
    }, [_vm._v(" Pilihan " + _vm._s(i + 1) + " ")])]), _c('tr', [_c('td', [_vm._v("Nama")]), _c('td', [_vm._v(_vm._s(item.school.name))])]), _c('tr', [_c('td', [_vm._v("Target Jurusan")]), _c('td', [_vm._v(_vm._s(item.name))])]), _c('tr', [_c('td', [_vm._v("Nilai Ambang Batas")]), _c('td', [_vm._v(" " + _vm._s(item.passing_grade_percentage ? _vm.passing_grade_percentage : "-") + " ")])]), _c('tr', [_c('td', [_vm._v("Total Skor")]), _c('td', [_vm._v(_vm._s(item.nilai_akhir))])])])]);
  }), 1)], 1)], 1) : _vm._e()], 1) : _vm._e(), _vm.paketDetail.rasionalisasi_type == 'sma' ? _c('b-card', [_c('b-table-simple', {
    attrs: {
      "responsive": "",
      "bordered": ""
    }
  }, [_c('b-tr', {
    staticStyle: {
      "background-color": "#fef000"
    }
  }, [_c('b-th', {
    staticClass: "text-center",
    attrs: {
      "colspan": "6"
    }
  }, [_c('h1', {
    staticClass: "text-dark"
  }, [_vm._v("BIMBINGAN BELAJAR")])])], 1), _c('b-tr', {
    staticStyle: {
      "background-color": "#bebfc3"
    }
  }, [_c('b-th', {
    staticClass: "text-center",
    attrs: {
      "colspan": "6"
    }
  }, [_c('h2', {
    staticClass: "text-dark"
  }, [_vm._v(_vm._s(_vm.hasilRasionalisasi.title))])])], 1), _c('b-tr', [_c('th', [_vm._v("Nama")]), _c('td', {
    attrs: {
      "colspan": "5"
    }
  }, [_vm._v(_vm._s(_vm.hasilRasionalisasi.name))])]), _c('b-tr', [_c('th', [_vm._v("Sekolah")]), _c('td', {
    attrs: {
      "colspan": "5"
    }
  }, [_vm._v(" " + _vm._s(_vm.hasilRasionalisasi.school_origin ? _vm.hasilRasionalisasi.school_origin.nama_sekolah : "-") + " ")])]), _c('b-tr', {
    staticStyle: {
      "background-color": "#bebfc3"
    }
  }, [_c('b-th', {
    staticClass: "text-center",
    attrs: {
      "colspan": "6"
    }
  }, [_c('h4', {
    staticClass: "text-dark"
  }, [_vm._v("HASIL TRY OUT")])])], 1), _c('b-tr', [_c('b-th', {
    staticClass: "text-center",
    staticStyle: {
      "background-color": "#bebfc3"
    },
    attrs: {
      "rowspan": "2"
    }
  }, [_c('h3', {
    staticClass: "text-primary"
  }, [_c('b', [_vm._v(" TRY OUT")])])]), _c('b-th', {
    staticClass: "text-center text-dark",
    staticStyle: {
      "background-color": "#a8a7a9"
    },
    attrs: {
      "colspan": "5"
    }
  }, [_c('b', [_vm._v(" NILAI PER MAPEL SATUAN ")])])], 1), _c('b-tr', {
    staticStyle: {
      "background-color": "#bebfc3"
    }
  }, [_c('b-th', {
    staticClass: "text-primary"
  }, [_vm._v("BENAR")]), _c('b-th', {
    staticClass: "text-primary"
  }, [_vm._v("SALAH")]), _c('b-th', [_vm._v("Nilai TO")]), _c('b-th', [_vm._v("*KK")]), _c('b-th', [_vm._v("Keterangan")])], 1), _vm._l(_vm.hasilRasionalisasi.nilai_per_mapel, function (item, i) {
    return _c('b-tr', {
      key: i
    }, [_c('th', [_vm._v(_vm._s(item.ujian_name))]), _c('td', [_vm._v(_vm._s(item.jumlah_benar))]), _c('td', [_vm._v(_vm._s(item.jumlah_salah))]), _c('td', [_vm._v(_vm._s(item.nilai))]), _c('td', [_vm._v(_vm._s(item.grade ? item.grade : "-"))]), _c('td', [_vm._v(_vm._s(item.keterangan ? item.keterangan : "-"))])]);
  }), _c('b-tr', {
    staticStyle: {
      "background-color": "#bebfc3"
    }
  }, [_c('b-th', {
    attrs: {
      "colspan": "6"
    }
  })], 1), _c('b-tr', {
    staticStyle: {
      "background-color": "#fef000"
    }
  }, [_c('th', [_vm._v("JUMLAH NILAI")]), _c('td', {
    attrs: {
      "colspan": "5"
    }
  }, [_vm._v(_vm._s(_vm.hasilRasionalisasi.jumlah_nilai))])]), _c('b-tr', {
    staticStyle: {
      "background-color": "#fef000"
    }
  }, [_c('th', [_vm._v("RATA-RATA NILAI")]), _c('td', {
    attrs: {
      "colspan": "5"
    }
  }, [_vm._v(_vm._s(_vm.hasilRasionalisasi.nilai_rata))])]), _c('b-tr', {
    staticStyle: {
      "background-color": "#fef000"
    }
  }, [_c('th', [_vm._v("*KK RATA-RATA NILAI")]), _c('td', {
    attrs: {
      "colspan": "5"
    }
  }, [_vm._v(_vm._s(_vm.hasilRasionalisasi.nilai_rata_grade))])]), _c('b-tr', {
    staticStyle: {
      "background-color": "#fef000"
    }
  }, [_c('th', [_vm._v("RANGKING")]), _c('td', {
    attrs: {
      "colspan": "5"
    }
  }, [_vm._v(_vm._s(_vm.hasilRasionalisasi.ranking.ke))])])], 2)], 1) : _vm._e(), _vm.paketDetail.rasionalisasi_type == 'kedinasan' ? _c('b-card', [_c('b-table-simple', {
    attrs: {
      "responsive": "",
      "bordered": ""
    }
  }, [_c('b-tr', {
    staticStyle: {
      "background-color": "#fef000"
    }
  }, [_c('b-th', {
    staticClass: "text-center",
    attrs: {
      "colspan": "6"
    }
  }, [_c('h1', {
    staticClass: "text-dark"
  }, [_vm._v("BIMBINGAN BELAJAR")])])], 1), _c('b-tr', {
    staticStyle: {
      "background-color": "#bebfc3"
    }
  }, [_c('b-th', {
    staticClass: "text-center",
    attrs: {
      "colspan": "6"
    }
  }, [_c('h2', {
    staticClass: "text-dark"
  }, [_vm._v("Kedinasan")])])], 1), _c('b-tr', [_c('th', [_vm._v("Nama")]), _c('td', {
    attrs: {
      "colspan": "5"
    }
  }, [_vm._v(_vm._s(_vm.hasilRasionalisasi.user_name))])]), _c('b-tr', [_c('th', [_vm._v("Jurusan")]), _c('td', {
    attrs: {
      "colspan": "5"
    }
  }, [_vm._v(" " + _vm._s(_vm.hasilRasionalisasi.jurusan_pilihan.school.name) + " ")])]), _c('b-tr', [_c('th', [_vm._v("Asal Sekolah")]), _c('td', {
    attrs: {
      "colspan": "5"
    }
  }, [_vm._v(" " + _vm._s(_vm.hasilRasionalisasi.school_origin ? _vm.hasilRasionalisasi.school_origin.nama_sekolah : "-") + " ")])]), _c('b-tr', {
    staticStyle: {
      "background-color": "#bebfc3"
    }
  }, [_c('b-th', {
    staticClass: "text-center",
    attrs: {
      "colspan": "6"
    }
  }, [_c('h4', {
    staticClass: "text-dark"
  }, [_vm._v("HASIL TRY OUT")])])], 1), _vm._l(_vm.hasilRasionalisasi.subcats, function (item, i) {
    return _c('b-tbody', {
      key: i
    }, [_c('b-tr', [_c('b-th', {
      staticClass: "text-dark",
      staticStyle: {
        "background-color": "#a8a7a9"
      },
      attrs: {
        "colspan": "6"
      }
    }, [_c('h4', [_c('b', [_vm._v(" " + _vm._s(item.name) + " ")])])])], 1), _vm._l(item.items, function (data, index) {
      return item.items ? _c('b-tr', {
        key: index
      }, [_c('b-th', [_vm._v(_vm._s(data.ujian_name))]), _c('b-th', [_vm._v(_vm._s(data.nilai))]), _c('b-th', [_vm._v(_vm._s(data.is_passed == true ? "Lulus" : "Tidak Lulus"))])], 1) : _vm._e();
    }), _c('b-tr', {
      staticStyle: {
        "background-color": "#c5d9f1"
      }
    }, [_c('b-th', {
      staticClass: "text-danger"
    }, [_vm._v("SKOR")]), _c('b-th', {
      staticClass: "text-danger"
    }, [_vm._v(_vm._s(item.is_using_percentage == true ? item.sum_percentage_nilai : item.total_nilai))]), _c('b-th', {
      staticClass: "text-danger"
    }, [_vm._v(_vm._s(item.is_passed == true ? "Lulus" : "Tidak Lanjut Ke Tahap Selanjutnya"))])], 1)], 2);
  })], 2)], 1) : _vm._e(), _vm.paketDetail.rasionalisasi_type == 'bintara' ? _c('b-card', [_c('b-table-simple', {
    attrs: {
      "responsive": "",
      "bordered": ""
    }
  }, [_c('b-tr', {
    staticStyle: {
      "background-color": "#fef000"
    }
  }, [_c('b-th', {
    staticClass: "text-center",
    attrs: {
      "colspan": "6"
    }
  }, [_c('h1', {
    staticClass: "text-dark"
  }, [_vm._v("BIMBINGAN BELAJAR")])])], 1), _c('b-tr', {
    staticStyle: {
      "background-color": "#bebfc3"
    }
  }, [_c('b-th', {
    staticClass: "text-center",
    attrs: {
      "colspan": "6"
    }
  }, [_c('h2', {
    staticClass: "text-dark"
  }, [_vm._v("Bintara")])])], 1), _c('b-tr', [_c('th', [_vm._v("Nama")]), _c('td', {
    attrs: {
      "colspan": "5"
    }
  }, [_vm._v(_vm._s(_vm.hasilRasionalisasi.user_name))])]), _c('b-tr', [_c('th', [_vm._v("Asal Sekolah")]), _c('td', {
    attrs: {
      "colspan": "5"
    }
  }, [_vm._v(" " + _vm._s(_vm.hasilRasionalisasi.school_origin ? _vm.hasilRasionalisasi.school_origin.nama_sekolah : "-") + " ")])]), _c('b-tr', {
    staticStyle: {
      "background-color": "#bebfc3"
    }
  }, [_c('b-th', {
    staticClass: "text-center",
    attrs: {
      "colspan": "6"
    }
  }, [_c('h4', {
    staticClass: "text-dark"
  }, [_vm._v("HASIL TRY OUT")])])], 1), _vm._l(_vm.hasilRasionalisasi.subcats, function (item, i) {
    return _c('b-tbody', {
      key: i
    }, [_c('b-tr', [_c('b-th', {
      staticClass: "text-dark",
      staticStyle: {
        "background-color": "#a8a7a9"
      },
      attrs: {
        "colspan": "6"
      }
    }, [_c('h4', [_c('b', [_vm._v(" " + _vm._s(item.name) + " ")])])])], 1), _vm._l(item.items, function (data, index) {
      return item.items ? _c('b-tr', {
        key: index
      }, [_c('b-th', [_vm._v(_vm._s(data.ujian_name))]), _c('b-th', {
        attrs: {
          "colspan": "2"
        }
      }, [_vm._v(_vm._s(data.nilai))])], 1) : _vm._e();
    }), _c('b-tr', {
      staticStyle: {
        "background-color": "#c5d9f1"
      }
    }, [_c('b-th', {
      staticClass: "text-danger"
    }, [_vm._v("SKOR")]), _c('b-th', {
      staticClass: "text-danger"
    }, [_vm._v(_vm._s(item.total_nilai))]), _c('b-th', {
      staticClass: "text-danger"
    }, [_vm._v(_vm._s(item.is_passed == true ? "Lulus" : "Tidak Lanjut Ke Tahap Selanjutnya"))])], 1)], 2);
  })], 2)], 1) : _vm._e(), _c('b-card', {
    staticStyle: {
      "margin-left": "7%",
      "margin-right": "7%"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "table-responsive"
  }, [_c('table', {
    staticClass: "table table-bordered responsive"
  }, [_c('tr', {
    staticClass: "bg-primary text-white",
    attrs: {
      "variant": "primary"
    }
  }, [_c('th', [_vm._v("No")]), _c('th', [_vm._v("Jenis")]), _c('th', [_vm._v("Subtes")]), _c('th', [_vm._v("Jumlah Soal")]), _c('th', [_vm._v("B")]), _c('th', [_vm._v("S")]), _c('th', [_vm._v("K")]), _c('th', [_vm._v("T")]), _c('th', [_vm._v("Pembahasan")])]), _vm._l(_vm.listRaport.scores, function (item, i) {
    return _c('tr', {
      key: i
    }, [_c('td', [_vm._v(_vm._s(i + 1))]), _c('td', [_vm._v(_vm._s(item.ujian_shortname))]), _c('td', [_vm._v(_vm._s(item.ujian_name))]), _c('td', [_vm._v(_vm._s(item.jumlah_soal))]), _c('td', [_vm._v(_vm._s(item.jumlah_benar))]), _c('td', [_vm._v(_vm._s(item.jumlah_salah))]), _c('td', [_vm._v(_vm._s(item.jumlah_kosong))]), _c('td', [_c('b-badge', {
      attrs: {
        "variant": "success"
      }
    }, [_vm._v(" " + _vm._s(item.nilai) + " ")])], 1), _c('td', [item.hasil_id != null ? _c('b-button', {
      attrs: {
        "variant": "outline-primary"
      },
      on: {
        "click": function click($event) {
          return _vm.lihatPembahasan(item);
        }
      }
    }, [_vm._v("Pembahasan")]) : _c('b-button', {
      attrs: {
        "variant": "outline-primary",
        "disabled": ""
      }
    }, [_vm._v("Pembahasan")])], 1)]);
  }), _c('tr', [_c('td', {
    staticClass: "text-center",
    attrs: {
      "colspan": "7"
    }
  }, [_vm._v("Total")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "colspan": "2"
    }
  }, [_c('b-badge', {
    staticClass: "text-center",
    attrs: {
      "variant": "danger"
    }
  }, [_vm._v(" " + _vm._s(_vm.sumScores))])], 1)])], 2)])])], 1)], 1), _c('br'), _c('br')], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }